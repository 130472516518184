<template>
  <div>
    <video
      ref="videoPlayer"
      class="video-js"
      :muted="true"
      :loop="false"
      controls
    ></video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayerThumbnail",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    previewTime: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.volume(0);
      this.player.currentTime(this.previewTime);
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
